<template>
  <b-container>
    <b-row>

      <b-col cols="12">
        <b-card
            header="Labot normatīvo aktu"
            header-tag="header"
            footer-tag="footer"
            footer=" "
        >
          <b-col cols="12" class="text-center" v-if="!form.name">
            <b-spinner variant="success" label="Spinning"></b-spinner>
          </b-col>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'success'"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Normatīvais akts tika veiksmīgi atjaunots!</p>
            <b-progress
                variant="success"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-alert
              :show="dismissCountDown"
              v-if="created == 'error'"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
          >
            <p>Ups! Notika kļūda</p>
            <p>{{ errorMessage }}</p>
            <b-progress
                variant="danger"
                :max="dismissSecs"
                :value="dismissCountDown"
                height="4px"
            ></b-progress>
          </b-alert>

          <b-form @submit="onSubmit" enctype="multipart/form-data" v-if="form.name">

            <b-form-group
                id="input-group-1"
                label="Lauka veids:"
                label-for="input-1"
            >

              <b-form-select v-model="form.type" :options="options" required></b-form-select>

            </b-form-group>

            <b-form-group
                id="input-group-2"
                label="Nosaukums:"
                label-for="input-2"
            >

              <b-form-input v-model="form.name"></b-form-input>

            </b-form-group>

            <b-form-group
                id="input-group-3"
                label="Atjaunošanas datums:"
                label-for="input-3"
            >

              <b-form-input type="date"v-model="form.updated_at" required>

              </b-form-input>

            </b-form-group>

            <b-form-group
                id="input-group-15"
                label="Fails:"
                label-for="input-15"
            >

              <b-form-file
                  v-model="form.file"
                  placeholder="Izvēlieties failu vai ievelciet to šeit..."
                  drop-placeholder="Ievelciet failu šeit..."
                  @change="onFileChange"
              ></b-form-file>

            </b-form-group>

            <b-form-group
                id="input-group-2"
            >

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.email"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="not_accepted"
              >
                Sūtīt e-pasta paziņojumu visiem darbiniekiem
              </b-form-checkbox>

            </b-form-group>

            <b-button type="submit" variant="success">Saglabāt</b-button>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import jsonToFormData from "@ajoelp/json-to-formdata";

export default {
  data() {
    return {
      created: null,
      errorMessage: null,
      dismissSecs: 5,
      dismissCountDown: 0,
      form: {
        type: null,
        name: null,
        updated_at: '',
        file: null,
      },
      fields : [
        { key: 'text', label: 'Tips' },
      ],
      options: [
        { text: 'Dokuments', value: 0 },
        { text: 'Instrukcija', value: 1 },
      ]
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      axios.post('/regulations/'+this.$route.params.id, jsonToFormData({_method: 'PATCH'}, {}, jsonToFormData(this.form))).then(response => {
        this.created = 'success';

      }).catch(error => {
        this.created = 'error';
        this.errorMessage = error;
      })

      this.dismissCountDown = 5;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    onFileChange(event) {
      this.form.file = event.target.files[0]
    },
    getData() {
      axios.get('/regulations/'+this.$route.params.id).then(response => {
        let data = response.data.data;
        this.form.type = data.type;
        this.form.name = data.name;
        this.form.updated_at = data.updated_at;
      });
    },
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>